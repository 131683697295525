import { XhrResponse, XhrResponseHandler } from 'ajax-hook'

import AbstractProcesser from './AbstractProcesser'

class ResponseProcessor extends AbstractProcesser {
  private response: XhrResponse
  private handler: XhrResponseHandler
  private onResponse: (
    response: XhrResponse,
    handler: XhrResponseHandler
  ) => void

  constructor(
    response: XhrResponse,
    handler: XhrResponseHandler,
    onResponse = (response: XhrResponse, handler: XhrResponseHandler) =>
      handler.next(response)
  ) {
    super()

    this.response = response
    this.handler = handler
    this.onResponse = onResponse
  }

  process(): void {
    this.onResponse(this.response, this.handler)
  }
}

export default ResponseProcessor
