import { Proxy, proxy, unProxy } from 'ajax-hook'

import AbstractInterceptor from './AbstractInterceptor'
import ComposeBuilder from '../Builder/ComposeBuilder'
import { Mode } from '..'

class AjaxHookInterceptor extends AbstractInterceptor {
  private interceptConfig: Proxy

  constructor(
    mode: Mode,
    onRequest?: Proxy['onRequest'],
    onResponse?: Proxy['onResponse'],
    onError?: Proxy['onError']
  ) {
    super()

    this.interceptConfig = new ComposeBuilder(
      mode,
      onRequest,
      onResponse,
      onError
    ).build()
  }

  public intercept(): XMLHttpRequest {
    return proxy(this.interceptConfig)
  }

  public unIntercept(): void {
    return unProxy()
  }
}

export default AjaxHookInterceptor
