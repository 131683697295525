import { RequiredHeaders } from '.'
import md5 from 'md5'

class Cipher {
  private config: RequiredHeaders

  constructor(config: RequiredHeaders) {
    this.config = config
  }

  public encrypt(): string {
    return md5(
      `x-op-module:${this.config['x-op-module']}\nx-op-event:${this.config['x-op-event']}\nx-event-id:${this.config['x-event-id']}`
    )
  }
}

export default Cipher
