import { Proxy, XhrRequestConfig, XhrRequestHandler } from 'ajax-hook'

import AbstractBuilder from './AbstractBuilder'
import { Mode } from '..'
import Processer from '../Processer/RequestProcesser'

class RequestBuilder extends AbstractBuilder {
  private mode: Mode
  private onRequest:
    | ((config: XhrRequestConfig, handler: XhrRequestHandler) => void)
    | undefined

  constructor(mode: Mode, onRequest?: Proxy['onRequest']) {
    super()

    this.mode = mode
    this.onRequest = onRequest
  }

  public build(): Proxy['onRequest'] {
    return (config: XhrRequestConfig, handler: XhrRequestHandler) =>
      new Processer(config, handler, this.mode, this.onRequest)
        .extractRequireHeaders()
        .findUniqueSearchItem()
        .process()
  }
}

export default RequestBuilder
