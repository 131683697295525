abstract class AbstractChecker {
  protected key: string
  protected value: any
  protected negative: boolean
  protected checked: boolean

  constructor(key: string, value: any) {
    this.key = key
    this.value = value
    this.checked = false
    this.negative = false
  }

  public not(): this {
    this.negative = true
    return this
  }

  public reload(key: string, value: any): this {
    this.key = key
    this.value = value

    return this
  }

  abstract check(): void
}

export default AbstractChecker
