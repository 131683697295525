import { ApiAdapter, Mode } from '..'

import AbstractBuilder from './AbstractBuilder'
import ErrorBuilder from './ErrorBuilder'
import { Proxy } from 'ajax-hook'
import RequestBuilder from './RequestBuilder'
import ResponseBuilder from './ResponseBuilder'

class ComposeBuilder extends AbstractBuilder {
  private builders: (RequestBuilder | ResponseBuilder | ErrorBuilder)[]

  constructor(
    mode: Mode,
    onRequest?: Proxy['onRequest'],
    onResponse?: Proxy['onResponse'],
    onError?: Proxy['onError']
  ) {
    super()

    this.builders = [
      new RequestBuilder(mode, onRequest),
      new ResponseBuilder(onResponse),
      new ErrorBuilder(onError)
    ]
  }

  public build(): Proxy {
    return this.builders.reduce((interceptConfig, builder) => {
      if (builder instanceof RequestBuilder) {
        interceptConfig.onRequest = builder.build()
      } else if (builder instanceof ResponseBuilder) {
        interceptConfig.onResponse = builder.build()
      } else if (builder instanceof ErrorBuilder) {
        interceptConfig.onError = builder.build()
      }

      return interceptConfig
    }, {} as Proxy)
  }
}

export default ComposeBuilder
