import Logger, { logger } from './Logger'

import Adapter from './Adapter'
import AjaxHookInterceptor from './Interceptor/AjaxHookInterceptor'
import ParamTypeChecker from './Checker/ParamTypeChecker'
import ParamTypeError from './Error/ParamTypeError'
import { Proxy } from 'ajax-hook'
import Transformer from './Transformer'
import isUndefined from 'lodash-es/isUndefined'

@logger('OperationLogSdk')
class OperationLogSdk {
  // SDK 元信息
  static namespace = '__NAMESPACE__'
  static version = '__VERSION__'

  private logger: Logger | undefined

  constructor(config: Config) {
    const {
      configList,
      module,
      mode = 'loose',
      log = true,
      apiAdapter,
      onRequest,
      onResponse,
      onError
    } = config
    const paramTypeChecker = new ParamTypeChecker('configList', configList)
      .isUndefined()
      .not()
      .isArray()
      .isEmpty()
      .check()

    if (!isUndefined(module)) {
      paramTypeChecker.reload('module', module).not().isString().check()
    }

    if (isUndefined((window as any).__operation_log_sdk_log)) {
      ;(window as any).__operation_log_sdk_log = log
    }

    if (isUndefined((window as any).__operation_log_sdk_api_adapters)) {
      ;(window as any).__operation_log_sdk_api_adapters = []
    }

    if (!isUndefined(apiAdapter)) {
      paramTypeChecker
        .reload('apiAdapter', apiAdapter)
        .not()
        .isFunction()
        .check()
      ;(window as any).__operation_log_sdk_api_adapters.push(apiAdapter)
    }

    try {
      ;(window as any).__operation_log_sdk_search_list = new Transformer(
        configList,
        module
      ).transform()
    } catch (error) {
      throw new ParamTypeError(
        'The datastruct of the param configList is broken, please check and pass again.'
      )
    }

    if (
      isUndefined((window as any).__operation_log_sdk_ajax_hook_interceptor)
    ) {
      ;(window as any).__operation_log_sdk_ajax_hook_interceptor = new AjaxHookInterceptor(
        mode,
        onRequest,
        onResponse,
        onError
      )
    }
  }

  public getSearchList: () => SearchItem[] = () => {
    return (window as any).__operation_log_sdk_search_list
  }

  public start: () => XMLHttpRequest = () => {
    if (!(window as any).__operation_log_sdk_started) {
      ;(window as any).__operation_log_sdk_started = true

      this.logger?.log('Intercepting has been started')

      return (window as any).__operation_log_sdk_ajax_hook_interceptor.intercept()
    }
  }

  public stop: () => void = () => {
    if ((window as any).__operation_log_sdk_started) {
      ;(window as any).__operation_log_sdk_started = false

      this.logger?.log('Intercepting has been stopped')

      return (window as any).__operation_log_sdk_ajax_hook_interceptor.unIntercept()
    }
  }
}

export default OperationLogSdk

export function getOPInfo(config: HeadersConfig & PossibleMessage): OPInfo {
  new ParamTypeChecker('config', config).isUndefined().check()

  const { toMacHeader, toReauiredHeaders } = new Adapter(
    config?.module,
    config?.event,
    config?.id,
    config?.message
  ).adapt()

  return {
    headers: toReauiredHeaders(),
    macHeader: toMacHeader()
  }
}

export interface Config {
  configList: ConfigItem[]
  module?: string
  mode?: Mode
  log?: boolean
  apiAdapter?: ApiAdapter
  onRequest?: Proxy['onRequest']
  onResponse?: Proxy['onResponse']
  onError?: Proxy['onError']
}

export interface Api {
  method: HttpMethod
  path: string
}

export interface Event {
  event: string
  name?: string
  message?: string
  apis: Api[]
}

export interface ConfigItem {
  module: string
  name?: string
  events: Event[]
}

export interface SearchItem {
  path: string
  method: HttpMethod
  event: string
  message?: string
  module: string
}

export interface HasId {
  id: string
}

// https://developer.mozilla.org/zh-CN/docs/Web/HTTP/Methods
export type HttpMethod =
  | 'GET'
  | 'HEAD'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'CONNECT'
  | 'OPTIONS'
  | 'TRACE'
  | 'PATCH'

export interface PossibleRequiredHeaders {
  'x-op-module'?: string
  'x-op-event'?: string
  'x-event-id'?: string
  'x-op-message'?: string
}

export interface RequiredHeaders {
  'x-op-module': string
  'x-op-event': string
  'x-event-id': string
  'x-op-message'?: string
}

export interface HeadersConfig {
  module: string
  event: string
  id?: string
}

export interface PossibleMessage {
  message?: string
}

export type Mode = 'strict' | 'loose'

export interface OPInfo {
  headers: RequiredHeaders
  macHeader: string
}

export interface ApiAdapter {
  (url: string, headers: any): string
}
