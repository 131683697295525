import {
  ApiAdapter,
  HasId,
  Mode,
  PossibleRequiredHeaders,
  SearchItem
} from '..'
import Logger, { logger } from '../Logger'
import { XhrRequestConfig, XhrRequestHandler } from 'ajax-hook'

import AbstractProcesser from './AbstractProcesser'
import Adapter from '../Adapter'
import AjaxHookError from '../Error/AjaxHookError'
import Code from '../Error/Code'
import Matcher from '../Matcher'
import { RequiredHeadersEnum } from '../Enum'
import cloneDeep from 'lodash-es/cloneDeep'
import isEmpty from 'lodash-es/isEmpty'
import isNull from 'lodash-es/isNull'
import isString from 'lodash-es/isString'
import isUndefined from 'lodash-es/isUndefined'
import toPairs from 'lodash-es/toPairs'

const { UNIQUE_EVENT_NOT_FOUND_ERROR_CODE } = Code

@logger('RequestProcesser')
class RequestProcesser extends AbstractProcesser {
  private config: XhrRequestConfig
  private handelr: XhrRequestHandler
  private mode: Mode
  private matcher: Matcher
  private requiredHeaders: PossibleRequiredHeaders = {}
  private uniqueSearchItem: SearchItem[] | (SearchItem & HasId) | null = null
  private logger: Logger | undefined
  private onRequest: (
    config: XhrRequestConfig,
    handler: XhrRequestHandler
  ) => void

  constructor(
    config: XhrRequestConfig,
    handelr: XhrRequestHandler,
    mode: Mode = 'loose',
    onRequest = (config: XhrRequestConfig, handler: XhrRequestHandler) =>
      handler.next(config)
  ) {
    super()

    this.config = config
    this.handelr = handelr
    this.mode = mode
    this.matcher = new Matcher()
    this.onRequest = onRequest
  }

  private loggerAndRejectError(): void {
    const error = new AjaxHookError(
      UNIQUE_EVENT_NOT_FOUND_ERROR_CODE,
      'Unique event is not found, please check the config you passed.',
      'error',
      this.config
    )

    this.logger?.error(error)
    this.handelr.reject(error)
  }

  private apiAdapter(url: string): string {
    return url
  }

  private buildFinalApiAdapter(): ApiAdapter {
    return (window as any).__operation_log_sdk_api_adapters
      .concat(this.apiAdapter)
      .reduce(
        (prev: ApiAdapter, curr: ApiAdapter) => (url: string, headers: any) => {
          let result = prev(url, headers)

          if (!isString(result)) {
            result = url
          }

          if (result !== url) {
            return result
          }

          return curr(result, headers)
        }
      )
  }

  public extractRequireHeaders(): this {
    const { headers } = this.config

    this.logger?.log('=====Divider=====')
    this.logger?.log('headers(before): ', cloneDeep(headers))

    this.requiredHeaders = toPairs<string>(headers).reduce(
      (requiredHeaders, [key, value]) => {
        if (
          Adapter.requiedHeaderKeys.indexOf(key as RequiredHeadersEnum) !== -1
        ) {
          requiredHeaders[key as RequiredHeadersEnum] = value
        }

        return requiredHeaders
      },
      {} as PossibleRequiredHeaders
    )

    return this
  }

  public findUniqueSearchItem(): this {
    const { method, url, headers } = this.config

    const finalApiAdapter = this.buildFinalApiAdapter()
    const adaptedUrl = finalApiAdapter(url, cloneDeep(headers))

    this.logger?.log('method: ', method)
    this.logger?.log('url: ', url)
    this.logger?.log('urlToMatch: ', adaptedUrl)

    this.uniqueSearchItem = this.matcher.match(
      method,
      adaptedUrl,
      this.requiredHeaders
    )

    return this
  }

  public process(): void {
    const {
      module,
      event,
      message: headerMessage
    } = this.matcher.formatHeaders(this.requiredHeaders)

    if (isNull(this.uniqueSearchItem)) {
      // 未找到

      if (this.mode === 'loose' && isUndefined(module) && isUndefined(event)) {
        // loose 模式下，
        // 直接放行请求
        this.onRequest(this.config, this.handelr)
      } else {
        this.loggerAndRejectError()
      }
    } else if (Array.isArray(this.uniqueSearchItem)) {
      // 找到多个
      this.loggerAndRejectError()
    } else {
      // 找到一个
      const { module, event, id, message } = this
        .uniqueSearchItem as SearchItem & HasId

      try {
        const requiredHeaders = new Adapter(
          module,
          event,
          id,
          // 以请求 headers 里的 message 为准
          // 如果没有传
          // 才去 SearchItem 里取
          headerMessage || message
        )
          .adapt(isEmpty(headerMessage))
          .toReauiredHeaders()

        this.config.headers = {
          ...this.config.headers,
          ...requiredHeaders
        }

        this.logger?.log('headers(after): ', cloneDeep(this.config.headers))

        this.onRequest(this.config, this.handelr)
      } catch (error) {
        this.loggerAndRejectError()
      }
    }
  }
}

export default RequestProcesser
