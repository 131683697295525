import { XhrError, XhrErrorType, XhrRequestConfig } from 'ajax-hook'

import BaseError from './BaseError'

class AjaxHookError extends BaseError implements XhrError {
  type: XhrErrorType
  config: XhrRequestConfig

  constructor(
    code: string,
    message: string,
    type: XhrErrorType,
    config: XhrRequestConfig
  ) {
    super(code, message)

    this.type = type
    this.config = config
  }
}

export default AjaxHookError
