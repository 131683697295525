import BaseError from './BaseError'
import Code from './Code'

const { PARAM_TYPE_ERROR_CODE } = Code

class ParamTypeError extends BaseError {
  constructor(message: string) {
    super(PARAM_TYPE_ERROR_CODE, message)
  }
}

export default ParamTypeError
