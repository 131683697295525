class BaseError extends Error {
  public code: string

  constructor(code: string, message: string) {
    super(message)

    this.code = code

    Object.setPrototypeOf(this, BaseError.prototype)
  }
}

export default BaseError
