import AbstractChecker from './AbstractChecker'
import ParamTypeError from '../Error/ParamTypeError'
import isEmpty from 'lodash-es/isEmpty'
import isFunction from 'lodash-es/isFunction'
import isString from 'lodash-es/isString'
import isUndefined from 'lodash-es/isUndefined'

class ParamTypeChecker extends AbstractChecker {
  private message: string | undefined

  public isUndefined(): this {
    if (!this.checked) {
      if (this.negative) {
        if (!isUndefined(this.value)) {
          this.message = `The param ${this.key} is defined.`
          this.checked = true
        }
      } else {
        if (isUndefined(this.value)) {
          this.message = `The param ${this.key} is undefined.`
          this.checked = true
        }
      }
    }

    this.negative = false

    return this
  }

  public isString(): this {
    if (!this.checked) {
      if (this.negative) {
        if (!isString(this.value)) {
          this.message = `The param ${this.key} is not a string.`
          this.checked = true
        }
      } else {
        if (isString(this.value)) {
          this.message = `The param ${this.key} is a string.`
          this.checked = true
        }
      }
    }

    this.negative = false

    return this
  }

  public isFunction(): this {
    if (!this.checked) {
      if (this.negative) {
        if (!isFunction(this.value)) {
          this.message = `The param ${this.key} is not a Function.`
          this.checked = true
        }
      } else {
        if (isFunction(this.value)) {
          this.message = `The param ${this.key} is a Function.`
          this.checked = true
        }
      }
    }

    this.negative = false

    return this
  }

  public isArray(): this {
    if (!this.checked) {
      if (this.negative) {
        if (!Array.isArray(this.value)) {
          this.message = `The param ${this.key} is not an array.`
          this.checked = true
        }
      } else {
        if (Array.isArray(this.value)) {
          this.message = `The param ${this.key} is an array.`
          this.checked = true
        }
      }
    }

    this.negative = false

    return this
  }

  public isEmpty(): this {
    if (!this.checked) {
      if (this.negative) {
        if (!isEmpty(this.value)) {
          this.message = `The param ${this.key} is not Empty.`
          this.checked = true
        }
      } else {
        if (isEmpty(this.value)) {
          this.message = `The param ${this.key} is Empty.`
          this.checked = true
        }
      }
    }

    this.negative = false

    return this
  }

  private _isUuid(str: string): boolean {
    const UUID_REG = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/

    return UUID_REG.test(str)
  }

  public isUuid(): this {
    if (!this.checked) {
      if (this.negative) {
        if (!this._isUuid(this.value)) {
          this.message = `The param ${this.key} is a invalid uuid.`
          this.checked = true
        }
      } else {
        if (this._isUuid(this.value)) {
          this.message = `The param ${this.key} is a valid uuid.`
          this.checked = true
        }
      }
    }

    this.negative = false
    return this
  }

  public check(): this {
    if (this.checked) {
      this.checked = false
      throw new ParamTypeError(this.message as string)
    }

    return this
  }
}

export default ParamTypeChecker
