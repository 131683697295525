import { Proxy, XhrResponse, XhrResponseHandler } from 'ajax-hook'

import AbstractBuilder from './AbstractBuilder'
import ResponseProcesser from '../Processer/ResponseProcesser'

class ResponseBuilder extends AbstractBuilder {
  private onResponse:
    | ((response: XhrResponse, handler: XhrResponseHandler) => void)
    | undefined

  constructor(onResponse?: Proxy['onResponse']) {
    super()
    this.onResponse = onResponse
  }

  public build(): Proxy['onResponse'] {
    return (response: XhrResponse, handler: XhrResponseHandler) =>
      new ResponseProcesser(response, handler, this.onResponse).process()
  }
}

export default ResponseBuilder
