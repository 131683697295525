import { Proxy, XhrError, XhrErrorHandler } from 'ajax-hook'

import AbstractBuilder from './AbstractBuilder'
import ErrorProcesser from '../Processer/ErrorProcesser'

class ErrorBuilder extends AbstractBuilder {
  private onError:
    | ((err: XhrError, handler: XhrErrorHandler) => void)
    | undefined

  constructor(onError?: Proxy['onError']) {
    super()

    this.onError = onError
  }

  public build(): Proxy['onError'] {
    return (err: XhrError, handler: XhrErrorHandler) =>
      new ErrorProcesser(err, handler, this.onError).process()
  }
}

export default ErrorBuilder
