import { Proxy } from 'ajax-hook'

abstract class AbstractBuilder {
  abstract build():
    | Proxy
    | Proxy['onRequest']
    | Proxy['onResponse']
    | Proxy['onError']
}

export default AbstractBuilder
