class Logger {
  private namespace = '__NAMESPACE__'
  private version = '__VERSION__'
  private clazz: string
  private console: Console

  constructor(clazz: string) {
    this.clazz = clazz
    this.console = window.console
  }

  @shouldLogOrNot
  public log(message: any, ...optionalParams: any[]): void {
    return this.console.log(
      `[${this.namespace}@${this.version}]->[${this.clazz}]: ${message}`,
      ...optionalParams
    )
  }

  @shouldLogOrNot
  public warn(message: any, ...optionalParams: any[]): void {
    return this.console.warn(
      `[${this.namespace}@${this.version}]->[${this.clazz}]: ${message}`,
      ...optionalParams
    )
  }

  @shouldLogOrNot
  public error(message: any, ...optionalParams: any[]): void {
    return this.console.error(
      `[${this.namespace}@${this.version}]->[${this.clazz}]: ${message}`,
      ...optionalParams
    )
  }
}

function shouldLogOrNot(
  target: Logger,
  name: string,
  descriptor: PropertyDescriptor
) {
  const oldValue = descriptor.value

  descriptor.value = function (...params: any[]) {
    if ((window as any).__operation_log_sdk_log) {
      return oldValue.apply(this, params)
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return function () {}
  }
}

export default Logger

export function logger(clazz: string) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return function (constructor: Function): void {
    constructor.prototype.logger = new Logger(clazz)
  }
}
