import { XhrError, XhrErrorHandler } from 'ajax-hook'

import AbstractProcesser from './AbstractProcesser'

class ErrorProcesser extends AbstractProcesser {
  private err: XhrError
  private handler: XhrErrorHandler
  private onError: (err: XhrError, handler: XhrErrorHandler) => void

  constructor(
    err: XhrError,
    handler: XhrErrorHandler,
    onError = (err: XhrError, handler: XhrErrorHandler) => handler.next(err)
  ) {
    super()

    this.err = err
    this.handler = handler
    this.onError = onError
  }

  process(): void {
    this.onError(this.err, this.handler)
  }
}

export default ErrorProcesser
