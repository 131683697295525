import Cipher from './Cipher'
import ParamTypeChecker from './Checker/ParamTypeChecker'
import { RequiredHeaders } from '.'
import { RequiredHeadersEnum } from './Enum'
import isEmpty from 'lodash-es/isEmpty'
import isUndefined from 'lodash-es/isUndefined'
import { v4 as uuidv4 } from 'uuid'

class Adapter {
  static requiedHeaderKeys = [
    RequiredHeadersEnum.X_OP_MODULE,
    RequiredHeadersEnum.X_OP_EVENT,
    RequiredHeadersEnum.X_EVENT_ID,
    RequiredHeadersEnum.X_OP_MESSAGE
  ]

  private xOpModule: string
  private xOpEvent: string
  private xEventId: string
  private xOpMessage: string | undefined
  private requiredHeaders: RequiredHeaders | undefined

  constructor(moudle: string, event: string, id?: string, message?: string) {
    this.xOpModule = moudle
    this.xOpEvent = event
    this.xEventId = isUndefined(id) ? uuidv4() : id
    this.xOpMessage = message
  }

  public adapt(encode = true): this {
    new ParamTypeChecker('xOpModule', this.xOpModule)
      .isUndefined()
      .not()
      .isString()
      .check()
      .reload('xOpEvent', this.xOpEvent)
      .isUndefined()
      .not()
      .isString()
      .check()
      .reload('xEventId', this.xEventId)
      .isUndefined()
      .not()
      .isString()
      .not()
      .isUuid()
      .check()

    const requiredHeaders: RequiredHeaders = {
      [RequiredHeadersEnum.X_OP_MODULE]: this.xOpModule,
      [RequiredHeadersEnum.X_OP_EVENT]: this.xOpEvent,
      [RequiredHeadersEnum.X_EVENT_ID]: this.xEventId,
      [RequiredHeadersEnum.X_OP_MESSAGE]: this.xOpMessage
    }

    if (!isEmpty(requiredHeaders[RequiredHeadersEnum.X_OP_MESSAGE])) {
      requiredHeaders[RequiredHeadersEnum.X_OP_MESSAGE] = encode
        ? encodeURIComponent(this.xOpMessage as string)
        : this.xOpMessage
    } else {
      delete requiredHeaders[RequiredHeadersEnum.X_OP_MESSAGE]
    }

    this.requiredHeaders = requiredHeaders

    return this
  }

  public toReauiredHeaders: () => RequiredHeaders = () => {
    new ParamTypeChecker('requiredHeaders', this.requiredHeaders)
      .isUndefined()
      .check()

    return this.requiredHeaders as RequiredHeaders
  }

  public toMacHeader: () => string = () => {
    new ParamTypeChecker('requiredHeaders', this.requiredHeaders)
      .isUndefined()
      .check()

    return new Cipher(this.requiredHeaders as RequiredHeaders).encrypt()
  }
}

export default Adapter
